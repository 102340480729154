<form [formGroup]="form" (ngSubmit)="onSubmit(false)" [hidden]="!showForm" class="entity-form">
  <div class="row">
    <div class="col-sm-12 col-md-6" [hidden]="editMode">
      <button kendoButton
              type="submit"
              [disabled]="!form.valid"
              [primary]="true"
              [icon]="'save'">
        Create {{ this.GetSingleEntityName() }} Type
      </button>
    </div>
    <div class="col-sm-12 col-md-6" [hidden]="!editMode">
      <kendo-buttongroup>
        <!--
            <button kendoButton
            type="submit"
            [disabled]="!form.valid"
            [primary]="true"
            [icon]="'save'">
                Save and Continue
            </button>
        -->
        <button kendoButton
                type="button"
                [primary]="true"
                [disabled]="!form.valid"
                [icon]="'save'"
                (click)="submitAndClose()">
          Save and Close
        </button>
        <button kendoButton
                type="button"
                [primary]="false"
                [icon]="'cancel-circle'"
                (click)="resetForm()">
          Cancel
        </button>
      </kendo-buttongroup>
    </div>
    <div class="col-sm-12 col-md-6" [hidden]="!editMode">
      <div class="float-end">
        <kendo-buttongroup>
        <!--
          <button kendoButton type="button" (click)="disable()" [icon]="'cancel-circle'">
              Disable {{this.GetSingleEntityName()}}
          </button>
          <button kendoButton type="button" (click)="delete()" [icon]="'delete'">
              Delete {{this.GetSingleEntityName()}}
          </button>
        -->
        </kendo-buttongroup>
        <div kendoDialogContainer></div>
      </div>
    </div>
    <div class="col-sm-12" [hidden]="true">
      <kendo-buttongroup>
        <button kendoButton
                type="button"
                [primary]="true"
                [icon]="'user'">
          View Users
        </button>
      </kendo-buttongroup>
    </div>
    <div class="col-sm-12">
      <hr />
    </div>
  </div>

  <app-validation-errors></app-validation-errors>
  <input type="hidden" id="id" formControlName="id" />

  <kendo-tabstrip>
    <!-- General -->
    <kendo-tabstrip-tab [title]="'General'" [selected]="true">
      <ng-template kendoTabContent>
        <div class="row">
          <div class="col-sm-12">
            <label for="name" class="col-form-label col-form-label-sm">
              Name
            </label>
            <input kendoTextBox add-required-asterisk required
              formControlName="name"
              id="name"
              class="form-control"
              placeholder="Name" />
            <validation-error-message [control]="name"></validation-error-message>
          </div>
          <div class="col-md-12">
            <label for="customId" class="col-form-label col-form-label-sm">
              Custom Id
            </label>
            <input kendoTextBox
              type="text"
              formControlName="customId"
              id="customId"
              class="form-control"
              placeholder="Custom Id" />
              <validation-error-message [control]="customId"></validation-error-message>
          </div>
          <div class="col-md-12">
            <label for="description" class="col-form-label col-form-label-sm">
              Description
            </label>
            <kendo-textarea
              formControlName="description"
              id="description"
              class="form-control"
              placeholder="Description">
            </kendo-textarea>
            <validation-error-message [control]="description"></validation-error-message>
          </div>
          <div class="col-md-12">
            <label for="notes" class="col-form-label col-form-label-sm">
              Notes
            </label>
            <kendo-textarea
              formControlName="notes"
              id="notes"
              class="form-control"
              placeholder="Notes">
            </kendo-textarea>
            <validation-error-message [control]="notes"></validation-error-message>
          </div>
          <div class="col-md-12">
            <label for="manufacturerVendorId" class="col-form-label col-form-label-sm">
              Manufacturer Vendor
            </label>
            <kendo-dropdownlist add-required-asterisk
              id="manufacturerVendorId"
              name="manufacturerVendorId"
              formControlName="manufacturerVendorId"
              valueField="id"
              textField="vendorName"
              [defaultItem]="vendorDefaultItem"
              [valuePrimitive]="true"
              [data]="($vendors | async)?.data"
              [filterable]="false">
            </kendo-dropdownlist>
            <validation-error-message [control]="manufacturerVendorId"></validation-error-message>
          </div>
          <div class="col-md-12">
            <label for="manufacturerNumber" class="col-form-label col-form-label-sm">
              Manufacturer Number
            </label>
            <input kendoTextBox
              type="text"
              formControlName="manufacturerNumber"
              id="manufacturerNumber"
              class="form-control"
              placeholder="Manufacturer Number" />
              <validation-error-message [control]="manufacturerNumber"></validation-error-message>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-3">
                <label for="minimumStockingQuantity" class="col-form-label col-form-label-sm">
                  Minimum Stocking Quantity
                </label>
                <input kendoTextBox
                  type="number"
                  formControlName="minimumStockingQuantity"
                  id="minimumStockingQuantity"
                  class="form-control"
                  placeholder="Minimum Stocking Quantity"
                  min="0"/>
              </div>
              <div class="col-md-3">
                <label for="stockingQuantityUnitOfMeasurement" class="col-form-label col-form-label-sm">
                  Unit of Measurement
                </label>
                <input kendoTextBox
                  type="text"
                  formControlName="stockingQuantityUnitOfMeasurement"
                  id="stockingQuantityUnitOfMeasurement"
                  class="form-control"
                  placeholder="Unit of Measurement" />
                  <validation-error-message [control]="stockingQuantityUnitOfMeasurement"></validation-error-message>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <label for="categories" class="col-form-label col-form-label-sm">
              Categories
            </label>
            <kendo-multiselect
              textField="categoryName"
              valueField="id"
              [allowCustom]="true"
              [data]="listItems"
              formControlName="categories"
              [valueNormalizer]="valueNormalizer">
            </kendo-multiselect>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <!-- Purchasing -->
    <kendo-tabstrip-tab [title]="'Purchasing'">
      <ng-template kendoTabContent>
        <div class="row">
          <div class="col-md-12">
            <label for="purchasingVendorId" class="col-form-label col-form-label-sm">
              Purchasing Vendor
            </label>
            <kendo-dropdownlist
              [defaultItem]="vendorDefaultItem"
              id="purchasingVendorId"
              name="purchasingVendorId"
              formControlName="purchasingVendorId"
              [valuePrimitive]="true"
              [data]="($vendors | async)?.data"
              [filterable]="false"
              valueField="id"
              textField="vendorName">
            </kendo-dropdownlist>
          </div>
          <div class="col-sm-12">
            <label for="costPerItem" class="col-form-label col-form-label-sm">
              Cost Per Item
            </label>
            <input kendoTextBox
              type="number"
              formControlName="costPerItem"
              id="costPerItem"
              class="form-control"
              placeholder="Cost Per Item" />
          </div>
          <div class="col-sm-12">
            <label for="costPerUnit" class="col-form-label col-form-label-sm">
              Cost Per Unit
            </label>
            <input kendoTextBox
              type="number"
              formControlName="costPerUnit"
              id="costPerUnit"
              class="form-control"
              placeholder="Cost Per Unit" />
          </div>
          <div class="col-sm-12">
            <label for="dateAcquired" class="col-form-label col-form-label-sm">
              Date Acquired
            </label>
            <kendo-datepicker format
              formControlName="dateAcquired"
              id="dateAcquired">
            </kendo-datepicker>
          </div>
          <div class="col-sm-12">
            <label for="accountName" class="col-form-label col-form-label-sm">
              Account Name
            </label>
            <input kendoTextBox
              formControlName="accountName"
              id="accountName"
              class="form-control"
              placeholder="Account Name" />
              <validation-error-message [control]="accountName"></validation-error-message>
          </div>
          <div class="col-sm-12">
            <label for="accountNumber" class="col-form-label col-form-label-sm">
              Account Number
            </label>
            <input kendoTextBox
              formControlName="accountNumber"
              id="accountNumber"
              class="form-control"
              placeholder="Account Number" />
              <validation-error-message [control]="accountNumber"></validation-error-message>
          </div>
          <div class="col-sm-12">
            <label for="purchaseOrderNumber" class="col-form-label col-form-label-sm">
              Purchase Order Number
            </label>
            <input kendoTextBox
              formControlName="purchaseOrderNumber"
              id="purchaseOrderNumber"
              class="form-control"
              placeholder="Purchase Order Number" />
              <validation-error-message [control]="purchaseOrderNumber"></validation-error-message>
          </div>
          <div class="col-sm-12">
            <label for="fundedBy" class="col-form-label col-form-label-sm">
              Funded By
            </label>
            <input kendoTextBox
              formControlName="fundedBy"
              id="fundedBy"
              class="form-control"
              placeholder="Funded By" />
          </div>
          <div class="col-sm-12">
            <label for="invoiceNumber" class="col-form-label col-form-label-sm">
              Invoice Number
            </label>
            <input kendoTextBox
              formControlName="invoiceNumber"
              id="invoiceNumber"
              class="form-control"
              placeholder="Invoice Number" />
              <validation-error-message [control]="invoiceNumber"></validation-error-message>
          </div>
          <div class="col-sm-12">
            <label for="productSku" class="col-form-label col-form-label-sm">
              Product SKU
            </label>
            <input kendoTextBox
              formControlName="productSku"
              id="productSku"
              class="form-control"
              placeholder="Product SKU" />
              <validation-error-message [control]="productSku"></validation-error-message>
          </div>
          <div class="col-sm-12">
            <label for="purchasingNotes" class="col-form-label col-form-label-sm">
              Purchasing Notes
            </label>
            <textarea kendoTextBox
              formControlName="purchasingNotes"
              id="purchasingNotes"
              class="form-control"
              placeholder="Purchasing Notes">
            </textarea>
            <validation-error-message [control]="purchasingNotes"></validation-error-message>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <!-- Metadata -->
    <kendo-tabstrip-tab [title]="'Metadata'" [disabled]="!editMode">
      <ng-template kendoTabContent>
        <app-metadata [metadata]="dataModel"></app-metadata>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</form>
