/**
 * Wingman Operations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ImportJobType = 'Inventory - Supply Types' | 'Inventory - Supply Inventories' | 'People - Customers' | 'Inventory - Manikin & Trainer Types' | 'Inventory - Equipment Types' | 'Inventory - Equipment Inventories' | 'Inventory - Manikin & Trainer Inventories';

export const ImportJobType = {
    InventorySupplyTypes: 'Inventory - Supply Types' as ImportJobType,
    InventorySupplyInventories: 'Inventory - Supply Inventories' as ImportJobType,
    PeopleCustomers: 'People - Customers' as ImportJobType,
    InventoryManikinTrainerTypes: 'Inventory - Manikin & Trainer Types' as ImportJobType,
    InventoryEquipmentTypes: 'Inventory - Equipment Types' as ImportJobType,
    InventoryEquipmentInventories: 'Inventory - Equipment Inventories' as ImportJobType,
    InventoryManikinTrainerInventories: 'Inventory - Manikin & Trainer Inventories' as ImportJobType
};

