<form class="" *ngIf="this.data?.modelInfo != null">
    <div class="card mb-3">
        <div class="card-body">
            <h5 class="card-title">Equipment Information</h5>
            <div class="row equipment-info-wrapper">
                <div class="col-xs-12 col-sm-6 mb-2">
                    <label class="col-form-label-sm">Name</label>
                    <input type="text" class="form-control-plaintext" readonly [value]="this.data?.modelInfo.name" />
                </div>
                <div class="col-xs-12 col-sm-6 mb-2">
                    <label class="col-form-label-sm">Manufacturer Name</label>
                    <input type="text" class="form-control-plaintext" readonly [value]="this.data?.modelInfo.manufacturerVendorName" />
                </div>
                <div class="col-xs-12 col-sm-6 mb-2">
                    <label class="col-form-label-sm">Model Number</label>
                    <input type="text" class="form-control-plaintext" readonly [value]="this.data?.modelInfo.modelNumber" />
                </div>
            </div>
        </div>
    </div>
</form>
<autopilot-grid>
    <kendo-grid
        [data]="gridData"
        [style.maxHeight.%]="100"
        [pageSize]="state.take"
        [filterable]="false"
        [sortable]="false"
        [sort]="state.sort"
        [loading]="isLoading" 
        [pageable]="true"
        [selectable]="{ checkboxOnly: true }"
        kendoGridSelectBy="id"
        [selectedKeys]="selectedIds"
        (selectionChange)="onSelectionChange($event)"
        (pageChange)="pageChangeEvent($event)"
        (dataStateChange)="dataStateChangeEvent($event)"
        >
        <ng-template kendoGridToolbarTemplate>
                
            <div class="container-fluid g-0 d-flex flex-wrap justify-content-center">
                <form class="col-auto mb-2 me-auto" role="search">
                    <button kendoButton type="button"  class="btn btn-primary text-white" [routerLink]="'/equipment/edit-list'"><i class="fa-light fa-arrow-left-long"></i> Return to Manage Manage Equipments</button>
                    <button kendoButton type="button" [routerLink]="'/equipment/' + this.equipmentId + '/line-items/add'"  class="btn btn-primary" ><i class="fa-light fa-plus"></i> Add {{this.GetSingleEntityName()}} Inventory Item</button>
                    <kendo-button (click)="onPrintLabels()"><i class="fa-light fa-qrcode"></i> Print Labels</kendo-button>
                </form>    
                <div class="text-end">
                    <button kendoGridPDFCommand class="btn btn-secondary text-white" title="Export as PDF"><i class="fa-light fa-file-pdf"></i></button>
                    <button kendoGridExcelCommand title="Export to Excel" class="btn btn-secondary text-white"><i class="fa-light fa-file-excel"></i></button>
                </div>
            </div>
        </ng-template>
        <kendo-grid-checkbox-column [width]="50" class="printer-checkbox-item">
            <ng-template kendoGridHeaderTemplate>
              <button class="btn btn-unstyled" title="Select/Deselect all rows for label printing" (click)="clickSelectAllCheckbox()">
                <i
                  class="fa-light fa-print"
                  [ngClass]="{ 'text-purple': selectAllState === 'checked', 'fw-bold': selectAllState === 'checked' }"></i>
                <input
                  #selectAllCheckbox
                  class="d-none k-checkbox k-checkbox-md k-rounded-md"
                  type="checkbox"
                  title="Select item(s) to print label(s)"
                  kendoCheckbox
                  kendoGridSelectAllCheckbox
                  [state]="selectAllState"
                  (selectAllChange)="onSelectAll($event)" />
              </button>
            </ng-template>
          </kendo-grid-checkbox-column>
        <kendo-grid-column title="Actions">
            <ng-template kendoGridCellTemplate let-dataItem>
                <button class="btn btn-primary default-action-button" kendoButton [primary]="true"  [routerLink]="'/equipment/' + this.equipmentId + '/line-items/edit/' + dataItem.id"  title="Edit this item"><i class="fas fa-edit"></i></button>
                <button kendoButton [primary]="true" class="btn btn-primary default-action-button" (click)="delete(dataItem.id)" title="Delete this item"> <i class="fas fa-trash"></i></button>
                <kendo-button [primary]="true" (click)="onQrClick(dataItem.id)"><i class="fa-light fa-qrcode"></i></kendo-button>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="wingmanId" title="Wingman ID">
            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                <kendo-grid-string-filter-menu
                    [column]="column"
                    [filter]="filter"
                    [filterService]="filterService"
                    [extra]="false">
                </kendo-grid-string-filter-menu>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="serialNumber" title="Serial Number/Tag Number">
            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                <kendo-grid-string-filter-menu
                    [column]="column"
                    [filter]="filter"
                    [filterService]="filterService"
                    [extra]="false">
                </kendo-grid-string-filter-menu>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="equipmentStatus" title="Equipment Status">
            <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                <kendo-multiselect
                    [data]="equipmentStatuses"
                    [placeholder]="enumFilterPlaceholder"
                    [value]="filter | filterValues"
                    (valueChange)="enumFilterChange($event, filterService, 'equipmentStatus')">
                </kendo-multiselect>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="customId" title="Custom ID">
            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                <kendo-grid-string-filter-menu
                    [column]="column"
                    [filter]="filter"
                    [filterService]="filterService"
                    [extra]="false">
                </kendo-grid-string-filter-menu>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="notes" title="Notes" [width]="200">
            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                <kendo-grid-string-filter-menu
                    [column]="column"
                    [filter]="filter"
                    [filterService]="filterService"
                    [extra]="false">
                </kendo-grid-string-filter-menu>
            </ng-template>
        </kendo-grid-column>
        <!-- <kendo-grid-column-group title="Current Location"> -->
            <kendo-grid-column field="locationName" title="Location">
                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                    <kendo-grid-string-filter-menu
                        [column]="column"
                        [filter]="filter"
                        [filterService]="filterService"
                        [extra]="false">
                    </kendo-grid-string-filter-menu>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="buildingName" title="Building">
                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                    <kendo-grid-string-filter-menu
                        [column]="column"
                        [filter]="filter"
                        [filterService]="filterService"
                        [extra]="false">
                    </kendo-grid-string-filter-menu>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="roomName" title="Room">
                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                    <kendo-grid-string-filter-menu
                        [column]="column"
                        [filter]="filter"
                        [filterService]="filterService"
                        [extra]="false">
                    </kendo-grid-string-filter-menu>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="bedName" title="Bed">
                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                    <kendo-grid-string-filter-menu
                        [column]="column"
                        [filter]="filter"
                        [filterService]="filterService"
                        [extra]="false">
                    </kendo-grid-string-filter-menu>
                </ng-template>
            </kendo-grid-column>
        <!-- </kendo-grid-column-group> -->
        <kendo-grid-excel [fileName]="excelFileName" [fetchData]="allData">
        </kendo-grid-excel>
    </kendo-grid>
</autopilot-grid>
<router-outlet></router-outlet>
<kendo-dialog *ngIf="isQrModalVisible" title="QR Code" (close)="isQrModalVisible=false">
    <kendo-qrcode
        [value]="qrLink"
    >
    </kendo-qrcode>
</kendo-dialog>
