import { EquipmentLineItemService, EquipmentService } from '@wo-api/index';
import { EntityGlobals, MenuGroupType } from '@wo-app/app.global';
import { EquipmentDetailComponent } from '@wo-app/components/dashboard/equipment/equipment-detail/equipment-detail.component';
import { EquipmentInventoryLocationListComponent } from '@wo-app/components/dashboard/equipment/equipment-inventory-location-list/equipment-inventory-location-list.component';
import { EquipmentQrLandingComponent } from '@wo-app/components/dashboard/equipment/equipment-qr-landing/equipment-qr-landing.component';
import { EquipmentLineItemDetailComponent } from '@wo-app/components/dashboard/equipment/line-item-details/detail.component';
import { EquipmentLineItemListComponent } from '@wo-app/components/dashboard/equipment/line-item-list/line-item-list.component';
import { ImpersonatingOrganizationCanActivate } from '@wo-app/core/guards';
import { MenuGroupListingItem } from '@wo-app/shared/models/menu';
import { RouteChild, RouteParent } from '@wo-app/shared/models/route';
// TODO: The following 3 component names are the same as those in components/inventoryitems. Let's rename them to be more specific.
import { InventoryItemListBuilderRowComponent } from '../components/dashboard/equipment/inventory-item-list-builder-row/inventory-item-list-builder-row.component';
import { InventoryItemListBuilderComponent } from '../components/dashboard/equipment/inventory-item-list-builder/inventory-item-list-builder.component';
import { InventoryItemsComponent } from '../components/dashboard/equipment/inventoryitems/inventoryitems.component';

export class EquipmentRegistration {
  static Routes: RouteParent = {
    path: EntityGlobals.EQUIPMENT.baseRoute,
    canActivate: [ImpersonatingOrganizationCanActivate],
    entityInformation: EntityGlobals.EQUIPMENT,
    children: [
      {
        path: '',
        component: EquipmentInventoryLocationListComponent,
        data: {
          breadcrumb: 'View ' + EntityGlobals.EQUIPMENT.pluralName + ' Inventory',
          menuGroups: [new MenuGroupListingItem(0, 1, MenuGroupType.InventoryAndEquipment, EntityGlobals.EQUIPMENT.pluralName)],
          entityInformation: EntityGlobals.EQUIPMENT
        }
      } as RouteChild,
      {
        path: 'edit-list',
        component: InventoryItemsComponent,
        data: {
          breadcrumb: `Manage ${EntityGlobals.EQUIPMENT.pluralName} Types`,
          //menuGroups: [new MenuGroupListingItem(1, 0, MenuGroupType.InventoryAndEquipment, 'Edit')],
          entityInformation: EntityGlobals.EQUIPMENT
        }
      } as RouteChild,
      {
        path: 'edit/:id',
        component: EquipmentDetailComponent,
        data: {
          breadcrumb: 'Edit ' + EntityGlobals.EQUIPMENT.singleName + ' Type',
          entityInformation: EntityGlobals.EQUIPMENT
        }
      } as RouteChild,
      {
        path: 'add',
        component: EquipmentDetailComponent,
        data: {
          breadcrumb: 'Add ' + EntityGlobals.EQUIPMENT.singleName + ' Type',
          entityInformation: EntityGlobals.EQUIPMENT
        }
      } as RouteChild,
      {
        path: ':equipmentId/line-items/edit/:id',
        component: EquipmentLineItemDetailComponent,
        data: {
          breadcrumb: 'Edit ' + EntityGlobals.EQUIPMENT.singleName + ' Inventory Item',
          entityInformation: EntityGlobals.EQUIPMENT
        }
      } as RouteChild,
      {
        path: ':equipmentId/line-items/add',
        component: EquipmentLineItemDetailComponent,
        data: {
          breadcrumb: 'Add ' + EntityGlobals.EQUIPMENT.singleName + ' Inventory Item',
          entityInformation: EntityGlobals.EQUIPMENT
        }
      } as RouteChild,
      {
        path: ':equipmentId/line-items',
        component: EquipmentLineItemListComponent,
        data: {
          breadcrumb: 'List Equipment Inventory Items',
          entityInformation: EntityGlobals.EQUIPMENT
        }
      } as RouteChild,
      {
        path: 'line-item/:equipmentLineItemId/qr',
        component: EquipmentQrLandingComponent,
        data: {
          breadcrumb: 'Edit Equipment Location',
          entityInformation: EntityGlobals.EQUIPMENT
        }
      } as RouteChild
    ]
  };

  static Services: any = [EquipmentService, EquipmentLineItemService];

  static Components: any = [
    EquipmentInventoryLocationListComponent,
    InventoryItemsComponent,
    EquipmentDetailComponent,
    InventoryItemListBuilderComponent,
    InventoryItemListBuilderRowComponent,
    EquipmentLineItemListComponent,
    EquipmentLineItemDetailComponent,
    EquipmentQrLandingComponent
  ];
}
