<div class="row">
    <div class="{{classWrapper}}">
        <label for="location_selector" class="col-form-label col-form-label-sm">Location</label>
        <kendo-dropdownlist
            name="location_selector"
            [data]="allLocationsDataCommandResult?.data"
            [value]="selectedLocation"
            [defaultItem]="defaultItemLocation"
            textField="name"
            valueField="id"
            [loading]="loading"
            (valueChange)="handleLocationChange($event)">
        </kendo-dropdownlist>
    </div>
    <div *ngIf="!isDisabledBuildings" class="{{classWrapper}}">
        <label for="building_selector" class="col-form-label col-form-label-sm">Building</label>
        <kendo-dropdownlist
            name="building_selector"
            [data]="dataResultBuildings"
            [value]="selectedBuilding"
            [defaultItem]="defaultItemBuilding"
            textField="name"
            valueField="id"
            (valueChange)="handleBuildingChange($event)">
        </kendo-dropdownlist>
    </div>
    <div *ngIf="!isDisabledRooms" class="{{classWrapper}}">
        <label for="room_selector" class="col-form-label col-form-label-sm">Room</label>
        <kendo-dropdownlist
            name="room_selector"
            [data]="dataResultRooms"
            [value]="selectedRoom"
            [defaultItem]="defaultItemRoom"
            textField="name"
            valueField="id"
            (valueChange)="handleRoomChange($event)">
        </kendo-dropdownlist>
    </div>
    <div *ngIf="!isDisabledBeds" class="{{classWrapper}}">
        <label for="bed_selector" class="col-form-label col-form-label-sm">Station</label>
        <kendo-dropdownlist
            name="bed_selector"
            [data]="dataResultBeds"
            [value]="selectedBed"
            [defaultItem]="defaultItemBed"
            textField="customId"
            valueField="id"
            (valueChange)="handleBedChange($event)">
        </kendo-dropdownlist>
    </div>
</div>