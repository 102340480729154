import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import {
  CreateImportJobCommandResult,
  GetImportJobSampleFileCommand,
  GetImportJobTypesCommandResult,
  ImportJobService,
  ImportJobType,
  ReadImportJobCommandResult,
  UpdateImportJobCommand,
  UpdateImportJobCommandResult
} from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-import-job-detail',
  templateUrl: './import-job-detail.component.html',
  styleUrls: ['./import-job-detail.component.scss']
})
export class ImportDataDetailComponent extends EntityBaseComponent implements OnInit {
  override showForm: boolean;
  readImportJobCommandResult: ReadImportJobCommandResult = {};
  dataModel: ReadImportJobCommandResult;
  updateImportJobCommand: UpdateImportJobCommand = {};
  form: FormGroup;
  originalFormState: FormGroup;
  fromKey: string;
  organizations: any[];
  selectedOrganizationId: string;
  public importJobTypes: Observable<GetImportJobTypesCommandResult>;
  public selectImportJobType: any;
  submitInProgress = false;

  constructor(
    private logger: NGXLogger,
    impersonationService: ImpersonationService,
    public importJobService: ImportJobService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private toastService: ToastService,
    injector: Injector
  ) {
    super(EntityGlobals.IMPORT_JOBS, injector);

    this.importJobTypes = this.importJobService.getImportJobTypes();

    /*concat(this.importJobTypes, route.params).subscribe((params:Params)=>{
      this.logger.debug('Getting Route Params');
      let importJobTypeParam = params["importJobType"];
      if(importJobTypeParam){
        if(importJobTypeParam.toLowerCase() == "inventory".toLowerCase()){
          this.selectImportJobType = ImportJobType.Inventory;
        }
        else if(importJobTypeParam.toLowerCase() == "InventoryQuantityRoom".toLowerCase()){
          this.selectImportJobType = ImportJobType.InventoryQuantityRoom;
        }
      }
    });*/
  }
  public myFiles: File[];

  public myRestrictions: FileRestrictions = {
    allowedExtensions: ['.csv']
  };

  ngOnInit() {
    this.createForm();
    this.showForm = false;
    this.fromKey = 'FormModel';
    if (this.editMode) {
      this.getData();
    } else {
      this.showForm = true;
    }
  }

  convertServerDataToFormModel(result: ReadImportJobCommandResult) {
    this.dataModel = result;
    this.form.patchValue(this.dataModel);
    if (this.originalFormState.pristine) {
      this.logger.debug('Setting Original Form State Value');
      this.originalFormState.patchValue(this.dataModel);
    }
  }

  getData() {
    this.importJobService
      .read(this.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: ReadImportJobCommandResult) => {
        this.logger.debug(result);
        this.convertServerDataToFormModel(result);
        this.showForm = true;
      });
  }

  resetForm() {
    this.logger.debug(this.originalFormState);
    this.form.reset(this.originalFormState.value);
    this.routeToEntityList();
  }

  getSampleCsvFileNameFromImportType(command: GetImportJobSampleFileCommand) {
    const dateString = new Date().toISOString().replace('T', ' ').replace(/-/g, '_').slice(0, 19);

    const importNamePrefix = 'Wingman_Data_Export_';
    switch (command.importJobType as ImportJobType | number) {
      case ImportJobType.InventorySupplyTypes:
      case 1:
        return `${importNamePrefix}_Supply_Types_${dateString}.csv`;
      case ImportJobType.InventorySupplyInventories:
      case 2:
        return `${importNamePrefix}_Supply_Inventory_${dateString}.csv`;
      case ImportJobType.PeopleCustomers:
      case 3:
        return `${importNamePrefix}Customers_${dateString}.csv`;
      case ImportJobType.InventoryManikinTrainerTypes:
      case 4:
        return `${importNamePrefix}Manikin_Types_${dateString}.csv`;
      case ImportJobType.InventoryEquipmentTypes:
      case 5:
        return `${importNamePrefix}Equipment_Types_${dateString}.csv`;
      case ImportJobType.InventoryEquipmentInventories:
      case 6:
        return `${importNamePrefix}Equipment_Inventory_${dateString}.csv`;
      case ImportJobType.InventoryManikinTrainerInventories:
      case 7:
        return `${importNamePrefix}Manikin_Inventory_${dateString}.csv`;
      default:
        return `${importNamePrefix}${dateString}.csv`;
    }
  }

  downloadSampleCsvFile() {
    const cmd: GetImportJobSampleFileCommand = {
      importJobType: this.selectImportJobType
    };
    this.logger.debug('getImportJobSampleFile command', cmd);
    this.importJobService.getImportJobSampleFile(cmd).subscribe({
      next: v => {
        this.logger.debug(v);
        const url = window.URL.createObjectURL(v);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = this.getSampleCsvFileNameFromImportType(cmd);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      },
      error: error => {
        this.logger.log('ERROR FLAGGED');
        this.logger.error(error);
        this.submitInProgress = false;
      },
      complete: () => {
        this.logger.log('COMPLETE FLAGGED');
        this.submitInProgress = false;
      }
    });
  }

  createForm() {
    this.form = this.fb.group({
      id: [''],
      importJobType: ['', [Validators.required]],
      file: [this.myFiles, Validators.required]
    });
    this.originalFormState = this.form;
  }

  onSubmit(closeOnSuccess) {
    this.submitInProgress = true;
    try {
      // If the Form is in Edit Mode, Update Object
      if (this.editMode) {
        this.updateImportJobCommand = this.form.value;
        this.logger.debug(this.updateImportJobCommand);
        this.importJobService
          .update(this.updateImportJobCommand.id.toString(), this.updateImportJobCommand)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (result: UpdateImportJobCommandResult) => {
            this.logger.debug(result);
            this.submitInProgress = false;
            if (closeOnSuccess) {
              this.routeToEntityList();
            } else {
              this.convertServerDataToFormModel(result);
              this.toastService.success('Success!', 'This item has been saved.');
            }
          }, error: error => {
            this.logger.log('ERROR FLAGGED');
            this.logger.error(error);
            this.submitInProgress = false;
          },
          complete: () => {
            this.logger.log('COMPLETE FLAGGED');
            this.submitInProgress = false;
          }});
      } else {
        // Otherwise, create a new object
        const file = this.form.controls['file'].value[0];
        this.importJobService
          .create(this.form.controls['importJobType'].value, file)
          .pipe(takeUntil(this.destroy$))
          .subscribe({ next: (result: CreateImportJobCommandResult) => {
            this.convertServerDataToFormModel(result);
            this.routeToViewEntity(result.id);
            this.submitInProgress = false;
            this.editMode = true;
            this.toastService.success('Success!', 'This item has been saved.');
          }, error: error => {
            this.logger.log('ERROR FLAGGED');
            this.logger.error(error);
            this.submitInProgress = false;
          },
          complete: () => {
            this.logger.log('COMPLETE FLAGGED');
            this.submitInProgress = false;
          }});
      }
    } catch {
      this.submitInProgress = false;
    }
  }

  submitAndClose() {
    this.onSubmit(true);
  }

  disable() {}

  delete() {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this importJob?',
      actions: [{ text: 'No' }, { text: 'Yes', primary: true }],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.pipe(takeUntil(this.destroy$)).subscribe(result => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
      }
      this.toastService.success('Success!', 'This item has been saved.');
    });
  }
}
