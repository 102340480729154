<autopilot-grid>
  <kendo-grid
      [data]="gridData"
      [style.maxHeight.%]="100"
      [pageSize]="state.take"
      [skip]="state.skip"
      [filterable]="false"
      [sortable]="false"
      [sort]="state.sort"
      [loading]="isLoading" [pageable]="true"
      (pageChange)="pageChangeEvent($event)"
      (dataStateChange)="dataStateChangeEvent($event)"
      >
      <ng-template kendoGridToolbarTemplate>
          <div class="container-fluid g-0 d-flex flex-wrap justify-content-center">
              <form class="col-auto me-auto" role="search">
                <button kendoButton type="button"  class="btn btn-secondary text-white" routerLink="/manikin-make-and-model"><i class="fa-light fa-arrow-left-long"></i> View {{this.GetPluralEntityName()}} Inventory</button>
                <button kendoButton type="button" class="btn btn-secondary text-light" (click)="addNewWithOrganizationId(organizationId)" ><i class="fa-light fa-plus"></i> Add {{this.GetSingleEntityName()}} Type</button>
              </form>
              <div class="text-end">
                      <button kendoGridPDFCommand class="btn btn-secondary text-white" title="Export as PDF"><i class="fa-light fa-file-pdf"></i></button>
                      <button kendoGridExcelCommand title="Export to Excel" class="btn btn-secondary text-white"><i class="fa-light fa-file-excel"></i></button>
              </div>
          </div>
      </ng-template>
      <kendo-grid-column title="Actions" [width]="158" class="k-col-actions">
          <ng-template kendoGridCellTemplate let-dataItem>
            <button kendoButton
                    class="btn btn-primary text-white default-action-button"
                    title="View Locations & Stocks"
                    [routerLink]="'/manikin-make-and-model/' + dataItem.id + '/line-items'">
              <i class="fa-inventory fa-light"></i>
            </button>
            <button kendoButton
                    class="btn btn-outline-primary default-action-button"
                    title="Edit this item"
                    [primary]="true"
                    (click)="routeToEditWithOrganization(dataItem.id)">
              <i class="fas fa-edit"></i>
            </button>
            <button kendoButton
                    class="btn btn-outline-danger default-action-button"
                    title="Delete this item"
                    [primary]="true"
                    (click)="delete(dataItem.id)">
              <i class="fas fa-trash"></i>
            </button>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="manufacturerVendorName" title="Manufacturer Name">
          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
              <kendo-grid-string-filter-menu
                  [column]="column"
                  [filter]="filter"
                  [filterService]="filterService"
                  [extra]="false">
              </kendo-grid-string-filter-menu>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="modelName" title="Model Name">
          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
              <kendo-grid-string-filter-menu
                  [column]="column"
                  [filter]="filter"
                  [filterService]="filterService"
                  [extra]="false">
              </kendo-grid-string-filter-menu>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="modelNumber" title="Model Number">
          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
              <kendo-grid-string-filter-menu
                  [column]="column"
                  [filter]="filter"
                  [filterService]="filterService"
                  [extra]="false">
              </kendo-grid-string-filter-menu>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="manikinOrTrainerBaseType" title="Type">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
              <kendo-multiselect
                  [data]="baseTypes"
                  [placeholder]="enumFilterPlaceholder"
                  [value]="filter | filterValues"
                  (valueChange)="enumFilterChange($event, filterService, 'manikinOrTrainerBaseType')">
              </kendo-multiselect>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="trainerType" title="Trainer Type">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
              <kendo-multiselect
                  [data]="trainerTypes"
                  [placeholder]="enumFilterPlaceholder"
                  [value]="filter | filterValues"
                  (valueChange)="enumFilterChange($event, filterService, 'trainerType')">
              </kendo-multiselect>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="manikinType" title="Manikin Type">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
              <kendo-multiselect
                  [data]="manikinTypes"
                  [placeholder]="enumFilterPlaceholder"
                  [value]="filter | filterValues"
                  (valueChange)="enumFilterChange($event, filterService, 'manikinType')">
              </kendo-multiselect>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-pdf [fileName]="pdfFileName" paperSize="A4" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
      </kendo-grid-pdf>
      <kendo-grid-excel [fileName]="excelFileName"></kendo-grid-excel>
  </kendo-grid>
</autopilot-grid>

<router-outlet></router-outlet>
<div kendoDialogContainer></div>
