import { Component, Injector, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import {
  CategoryService,
  CreateEquipmentCommandResult,
  CreateEquipmentLineItemCommand,
  EnumListResult,
  EnumValue,
  EquipmentLineItemService,
  ListCategorysCommandResult,
  ListVendorsCommandResult,
  OrganizationService,
  ReadEquipmentCommandResult,
  SupplyLocationQuantityService,
  UpdateEquipmentLineItemCommand,
  VendorService,
  VendorViewModel,
  ReadEquipmentLineItemCommandResult,
  ListVendorsCommand,
  EquipmentLineItemViewModel
} from '@wo-api/index';
import { EntityGlobals, ValidationMaxStringLength } from '@wo-app/app.global';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { CachedDataService, ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class EquipmentLineItemDetailComponent extends EntityBaseComponent implements OnInit {
  public dataModel: ReadEquipmentLineItemCommandResult;
  public modelInfo: EquipmentLineItemViewModel;

  public ReadEquipmentCommandResult: ReadEquipmentCommandResult = {};
  public UpdateEquipmentLineItemCommand: UpdateEquipmentLineItemCommand = {};
  public CreateEquipmentLineItemCommand: CreateEquipmentLineItemCommand = {};

  public form: FormGroup;
  public originalFormState: FormGroup;
  public fromKey: string;
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 5
  };

  private equipmentId: number;
  public inventoryItemTaskTypes: EnumValue[] = [];
  public listItems: any[] = [];
  public vendorDefaultItem: VendorViewModel = {
    id: -1,
    vendorName: 'Select an item...'
  };

  public primaryLocationSubject: BehaviorSubject<AbstractControl> = new BehaviorSubject<AbstractControl>(null);
  public currentLocationSubject: BehaviorSubject<AbstractControl> = new BehaviorSubject<AbstractControl>(null);

  public $EquipmentStatuses: Observable<EnumListResult>;
  public $locationsAndStocks: Observable<GridDataResult>;
  public $categories: Observable<ListCategorysCommandResult>;
  public $vendors: Observable<ListVendorsCommandResult>;

  constructor(
    private logger: NGXLogger,
    private categoryService: CategoryService,
    public cachedDataService: CachedDataService,
    impersonationService: ImpersonationService,
    private organizationService: OrganizationService,
    private equipmentLineItemService: EquipmentLineItemService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private vendorService: VendorService,
    private dialogService: DialogService,
    private toastService: ToastService,
    private SupplyLocationQuantityService: SupplyLocationQuantityService,
    breadcrumbService: BreadcrumbsService,
    titleService: Title,
    injector: Injector
  ) {
    super(EntityGlobals.EQUIPMENT, injector);

    this.$EquipmentStatuses = this.equipmentLineItemService.equipmentStatuses();
    const listVendorsCommand: ListVendorsCommand = {
      skip: 0,
      take: 1000
    };
    this.$vendors = this.vendorService.list(listVendorsCommand);
  }

  public ngOnInit() {
    this.equipmentId = this.route.snapshot.params['equipmentId'];
    this._createForm();

    if (this.editMode) {
      this._getData();
    } else {
      this._refreshLocations();
      this.showForm = true;
    }
  }

  private _parseObjectDates(target: any): any {
    const result = Object.assign({}, target);
    const dateFields = ['dateAcquired', 'warrantyExpirationDate', 'preventativeMaintenanceExpirationDate'];
    dateFields.forEach(key => {
      const date = new Date(result[key]);
      if (!isNaN(date.getTime())) {
        result[key] = date;
      } else {
        result[key] = null;
      }
    });
    return result;
  }

  private _convertServerDataToFormModel(result: ReadEquipmentLineItemCommandResult) {
    this.dataModel = result;
    this.dataModel = this._parseObjectDates(this.dataModel);
    this.modelInfo = this.dataModel.modelInfo;
    this.form.patchValue(this.dataModel);

    this.form.patchValue({
      currentLocation: {
        locationId: this.dataModel.currentLocationLocationId,
        buildingId: this.dataModel.currentLocationBuildingId,
        roomId: this.dataModel.currentLocationRoomId,
        stationId: this.dataModel.currentLocationStationId
      },
      primaryLocation: {
        locationId: this.dataModel.primaryLocationLocationId,
        buildingId: this.dataModel.primaryLocationBuildingId,
        roomId: this.dataModel.primaryLocationRoomId,
        stationId: this.dataModel.primaryLocationStationId
      }
    });
    if (this.originalFormState.pristine) {
      this.originalFormState.patchValue(this.dataModel);
    }
  }

  private _getData(): void {
    this.equipmentLineItemService.read(this.id).subscribe((result: ReadEquipmentLineItemCommandResult) => {
      this.UpdateBreadcrumbPageTitle(result.id, result.equipmentName);
      this._convertServerDataToFormModel(result);
      this._refreshLocations();
      this.showForm = true;
    });
  }

  private _createForm() {
    this.form = this.fb.group({
      id: [''],
      wingmanId: new FormControl({ value: '', disabled: true }),
      equipmentId: [this.equipmentId],
      serialNumber: ['', [Validators.required, Validators.maxLength(ValidationMaxStringLength.Default)]],
      customId: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],
      equipmentName: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],
      notes: ['', [Validators.maxLength(ValidationMaxStringLength.Long)]],
      equipmentStatus: [''],

      purchasingVendorId: [null],
      costPerItem: [null],
      costPerUnit: [null],
      accountName: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],
      accountNumber: ['', [Validators.maxLength(ValidationMaxStringLength.Short)]],
      productSku: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],
      purchaseOrderNumber: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],
      invoiceNumber: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],
      purchasingNotes: ['', [Validators.maxLength(ValidationMaxStringLength.Long)]],
      fundedBy: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],
      dateAcquired: [null],

      warrantyVendorId: [null],
      warrantyName: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],
      preventativeMaintenanceExpirationDate: [null],
      warrantyCost: [null],
      warrantyCostPerYear: [null],
      warrantyExpirationDate: [null],
      warrantyNotes: ['', [Validators.maxLength(ValidationMaxStringLength.Long)]],
      warrantyType: [null],
      warrantyExpiration: [null],

      created: [''],
      createByUsername: [''],
      createdByUserId: [''],
      lastUpdated: [''],
      lastUpdatedByUsername: [''],
      lastUpdatedByUserId: [''],
      categories: [''],

      currentLocation: this.fb.group({
        locationId: [null],
        buildingId: [null],
        roomId: [null],
        stationId: [null]
      }),
      primaryLocation: this.fb.group({
        locationId: [null],
        buildingId: [null],
        roomId: [null],
        stationId: [null]
      })
    });
    this.originalFormState = this.form;
  }

  public resetForm() {
    this.form.reset(this.originalFormState.value);
    this.routeToEntityList();
  }

  private _routeToEquipmentListForEquipmentId() {
    const route = '/' + this.GetEntityUrl() + '/' + this.equipmentId + '/line-items';
    this.router.navigate([route]);
  }

  public onSubmit(closeOnSuccess) {
    // If the Form is in Edit Mode, Update Object
    if (this.editMode) {
      this.UpdateEquipmentLineItemCommand = this.form.value;
      
      this.UpdateEquipmentLineItemCommand.primaryLocationLocationId = this.form.value.primaryLocation.locationId;
      this.UpdateEquipmentLineItemCommand.primaryLocationBuildingId = this.form.value.primaryLocation.buildingId;
      this.UpdateEquipmentLineItemCommand.primaryLocationRoomId = this.form.value.primaryLocation.roomId;
      this.UpdateEquipmentLineItemCommand.primaryLocationStationId = this.form.value.primaryLocation.stationId;
      
      this.UpdateEquipmentLineItemCommand.currentLocationLocationId = this.form.value.currentLocation.locationId;
      this.UpdateEquipmentLineItemCommand.currentLocationBuildingId = this.form.value.currentLocation.buildingId;
      this.UpdateEquipmentLineItemCommand.currentLocationRoomId = this.form.value.currentLocation.roomId;
      this.UpdateEquipmentLineItemCommand.currentLocationStationId = this.form.value.currentLocation.stationId;

      this.equipmentLineItemService
        .update(this.UpdateEquipmentLineItemCommand.id.toString(), this.UpdateEquipmentLineItemCommand)
        .subscribe(result => {
          if (closeOnSuccess) {
            this._routeToEquipmentListForEquipmentId();
          } else {
            this._convertServerDataToFormModel(result);
            this.toastService.success('Success!', 'This item has been saved.');
          }
        });
    } else {
      // Otherwise, create a new object
      this.CreateEquipmentLineItemCommand = this.form.value;

      this.CreateEquipmentLineItemCommand.primaryLocationLocationId = this.form.value.primaryLocation.locationId;
      this.CreateEquipmentLineItemCommand.primaryLocationBuildingId = this.form.value.primaryLocation.buildingId;
      this.CreateEquipmentLineItemCommand.primaryLocationRoomId = this.form.value.primaryLocation.roomId;
      this.CreateEquipmentLineItemCommand.primaryLocationStationId = this.form.value.primaryLocation.stationId;
      
      this.CreateEquipmentLineItemCommand.currentLocationLocationId = this.form.value.currentLocation.locationId;
      this.CreateEquipmentLineItemCommand.currentLocationBuildingId = this.form.value.currentLocation.buildingId;
      this.CreateEquipmentLineItemCommand.currentLocationRoomId = this.form.value.currentLocation.roomId;
      this.CreateEquipmentLineItemCommand.currentLocationStationId = this.form.value.currentLocation.stationId;

      this.equipmentLineItemService.create(this.CreateEquipmentLineItemCommand).subscribe((result: CreateEquipmentCommandResult) => {
        this._routeToEquipmentListForEquipmentId();
        this.editMode = true;
        this.toastService.success('Success!', 'This item has been saved.');
      });
    }
  }

  public submitAndClose() {
    this.onSubmit(true);
  }

  public delete() {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this inventoryItem?',
      actions: [{ text: 'No' }, { text: 'Yes', primary: true }],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe(result => {
      if (result instanceof DialogCloseResult) {
      } else {
        this.logger.debug('action', result);
      }
      this.toastService.success('Success!', 'This item has been saved.');
    });
  }

  public primaryLocationSelectorChangeEvent(location: FormGroup) {
    this.form.get('primaryLocation').setValue(location.value);
  }

  public currentLocationSelectorChangeEvent(location: FormGroup) {
    this.form.get('currentLocation').setValue(location.value);
  }

  private _refreshLocations() {
    const primaryLocation = this.form.get('primaryLocation');
    const currentLocation = this.form.get('currentLocation');

    this.primaryLocationSubject.next(primaryLocation);
    this.currentLocationSubject.next(currentLocation);
  }
}
