import { ManikinInventoryItemService, ManikinMakeAndModelService } from '@wo-api/index';
import { EntityGlobals, MenuGroupType } from '@wo-app/app.global';
import { ManikinInventoryItemDetailComponent } from '@wo-app/components/dashboard/manikin-make-and-model/manikin-inventory-item-detail/manikin-inventory-item-detail.component';
import { ManikinInventoryItemListComponent } from '@wo-app/components/dashboard/manikin-make-and-model/manikin-inventory-item-list/manikin-inventory-item-list.component';
import { ManikinMakeAndModelDetailComponent } from '@wo-app/components/dashboard/manikin-make-and-model/manikin-make-and-model-detail/manikin-make-and-model-detail.component';
import { ManikinMakeAndModelInventoryLocationListComponent } from '@wo-app/components/dashboard/manikin-make-and-model/manikin-make-and-model-inventory-location-list/manikin-make-and-model-inventory-location-list.component';
import { ManikinMakeAndModelListComponent } from '@wo-app/components/dashboard/manikin-make-and-model/manikin-make-and-model-list/manikin-make-and-model-list.component';
import { ManikinQrLandingComponent } from '@wo-app/components/dashboard/manikin-make-and-model/manikin-qr-landing/manikin-qr-landing.component';
import { NotImpersonatingOrganizationCanActivate } from '@wo-app/core/guards';
import { MenuGroupListingItem } from '@wo-app/shared/models/menu';
import { RouteChild, RouteParent } from '@wo-app/shared/models/route';

export class ManikinMakeAndModelRegistration {
  static Routes: RouteParent = {
    path: EntityGlobals.MANIKIN_MAKES_AND_MODELS.baseRoute,
    canActivate: [NotImpersonatingOrganizationCanActivate],
    entityInformation: EntityGlobals.MANIKIN_MAKES_AND_MODELS,
    children: [
      {
        path: '',
        component: ManikinMakeAndModelInventoryLocationListComponent,
        data: {
          entityInformation: EntityGlobals.MANIKIN_MAKES_AND_MODELS,
          breadcrumb: 'View ' + EntityGlobals.MANIKIN_MAKES_AND_MODELS.pluralName + ' Inventory',
          menuGroups: [
            new MenuGroupListingItem(0, 1, MenuGroupType.InventoryAndEquipment, EntityGlobals.MANIKIN_MAKES_AND_MODELS.pluralName)
          ]
        }
      } as RouteChild,
      {
        path: 'edit-list',
        component: ManikinMakeAndModelListComponent,
        data: {
          entityInformation: EntityGlobals.MANIKIN_MAKES_AND_MODELS,
          breadcrumb: `Manage ${EntityGlobals.MANIKIN_MAKES_AND_MODELS.pluralName} Types`
          //menuGroups: [new MenuGroupListingItem(1, 0, MenuGroupType.InventoryAndEquipment, 'Edit List')]
        }
      } as RouteChild,
      {
        path: 'edit/:id',
        component: ManikinMakeAndModelDetailComponent,
        data: {
          entityInformation: EntityGlobals.MANIKIN_MAKES_AND_MODELS,
          breadcrumb: 'Edit ' + EntityGlobals.MANIKIN_MAKES_AND_MODELS.singleName + ' Type'
        }
      } as RouteChild,
      {
        path: 'add',
        component: ManikinMakeAndModelDetailComponent,
        data: {
          entityInformation: EntityGlobals.MANIKIN_MAKES_AND_MODELS,
          breadcrumb: 'Add ' + EntityGlobals.MANIKIN_MAKES_AND_MODELS.singleName + ' Type'
        }
      } as RouteChild,
      {
        path: ':manikinMakeAndModelId/line-items/edit/:id',
        component: ManikinInventoryItemDetailComponent,
        data: {
          entityInformation: EntityGlobals.MANIKIN_MAKES_AND_MODELS,
          breadcrumb: 'Edit ' + EntityGlobals.MANIKIN_MAKES_AND_MODELS.singleName + ' Inventory Item'
        }
      } as RouteChild,
      {
        path: ':manikinMakeAndModelId/line-items/add',
        component: ManikinInventoryItemDetailComponent,
        data: {
          entityInformation: EntityGlobals.MANIKIN_MAKES_AND_MODELS,
          breadcrumb: 'Add ' + EntityGlobals.MANIKIN_MAKES_AND_MODELS.singleName + ' Inventory Item'
        }
      } as RouteChild,
      {
        path: ':manikinMakeAndModelId/line-items',
        component: ManikinInventoryItemListComponent,
        data: {
          entityInformation: EntityGlobals.MANIKIN_MAKES_AND_MODELS,
          breadcrumb: 'List Manikin/Trainer Inventory items'
        }
      } as RouteChild,
      {
        path: 'line-item/:manikinLineItemId/qr',
        component: ManikinQrLandingComponent,
        data: {
          entityInformation: EntityGlobals.MANIKIN_MAKES_AND_MODELS,
          breadcrumb: `Edit ${EntityGlobals.MANIKIN_MAKES_AND_MODELS.singleName} Location`
        }
      } as RouteChild
    ]
  };

  static Services: any = [ManikinMakeAndModelService, ManikinInventoryItemService];

  static Components: any = [
    ManikinMakeAndModelListComponent,
    ManikinMakeAndModelDetailComponent,
    ManikinInventoryItemDetailComponent,
    ManikinInventoryItemListComponent,
    ManikinMakeAndModelInventoryLocationListComponent,
    ManikinQrLandingComponent
  ];
}
