<form [formGroup]="form" (ngSubmit)="onSubmit(false)" [hidden]="!showForm" class="entity-form">
    <div class="row">
        <div class="col-xs-12 col-md-6" [hidden]="editMode">
            <button kendoButton
                    type="submit"
                    [disabled]="!form.valid"
                    [primary]="true"
                    [icon]="'save'">
              Create {{this.GetSingleEntityName()}} Type
            </button>
        </div>
        <div class="col-xs-12 col-md-6" [hidden]="!editMode">
            <kendo-buttongroup>
                <!--<button kendoButton type="submit" [disabled]="!form.valid" [primary]="true" [icon]="'save'">Save and Continue</button>-->
                <button kendoButton
                        type="button"
                        [primary]="true"
                        [disabled]="!form.valid"
                        [icon]="'save'"
                        (click)="submitAndClose()">
                          Save and Close</button>
                <button kendoButton
                        type="button"
                        [primary]="false"
                        [icon]="'cancel-circle'"
                        (click)="resetForm()">
                  Cancel
                </button>
            </kendo-buttongroup>
        </div>
        <div class="col-xs-12 col-md-6" [hidden]="!editMode">
            <div class="float-end">
                <kendo-buttongroup>
                    <!--<button kendoButton type="button" (click)="disable()" [icon]="'cancel-circle'">Disable {{this.GetSingleEntityName()}}</button>
              <button kendoButton type="button" (click)="delete()" [icon]="'delete'">Delete {{this.GetSingleEntityName()}}</button>-->
                </kendo-buttongroup>
                <div kendoDialogContainer></div>
            </div>
        </div>
        <div class="col-xs-12" [hidden]="true">
            <kendo-buttongroup>
                <button kendoButton
                        type="button"
                        [primary]="true"
                        [icon]="'user'">
                  View Users
                </button>
            </kendo-buttongroup>
        </div>
        <div class="col-xs-12">
            <hr/>
        </div>
    </div>
    <app-validation-errors></app-validation-errors>
    <input type="hidden" id="id" formControlName="id" />
    <kendo-tabstrip>
        <kendo-tabstrip-tab [title]="'General'" [selected]="true">
            <ng-template kendoTabContent>
                <div class="row">
                    <div class="col-xs-12">
                        <label for="manufacturerVendorId" class="col-form-label col-form-label-sm">
                          Manufacturer Vendor
                        </label>
                        <kendo-dropdownlist
                            add-required-asterisk
                            [defaultItem]="vendorDefaultItem"
                            id="manufacturerVendorId"
                            name="manufacturerVendorId"
                            formControlName="manufacturerVendorId"
                            [valuePrimitive]="true"
                            [data]="($vendors | async)?.data"
                            [filterable]="false"
                            valueField="id"
                            textField="vendorName">
                        </kendo-dropdownlist>
                    </div>
                    <div class="col-xs-12">
                        <label for="manikinOrTrainerBaseType" class="col-form-label col-form-label-sm">
                          Manikin or Trainer
                        </label>
                        <app-radio-button-list
                          formControlName="manikinOrTrainerBaseType"
                          [data]="(manikinOrTrainerBaseTypes | async)?.data"
                          [orientation]="'horizontal'"
                          [groupName]="'manikinOrTrainerBaseTypes'"
                          [showInline]="true" [dataLabelField]="'name'"
                          [dataValueField]="'name'">
                        </app-radio-button-list>
                    </div>
                    <div class="col-xs-12" *ngIf="form.get('manikinOrTrainerBaseType').value == Manikin">
                        <label for="manikinType" class="col-form-label col-form-label-sm">
                          Manikin Type
                        </label>
                        <app-radio-button-list
                          formControlName="manikinType"
                          [data]="(manikinTypes | async)?.data"
                          [orientation]="'horizontal'"
                          [groupName]="'manikinTypes'"
                          [showInline]="true" [dataLabelField]="'name'"
                          [dataValueField]="'name'">
                        </app-radio-button-list>
                    </div>
                    <div class="col-xs-12" *ngIf="form.get('manikinOrTrainerBaseType').value == Trainer">
                        <label for="trainerType" class="col-form-label col-form-label-sm">
                          Trainer Type
                        </label>
                        <app-radio-button-list
                          formControlName="trainerType"
                          [data]="(trainerTypes| async)?.data"
                          [orientation]="'horizontal'"
                          [groupName]="'trainerTypes'"
                          [showInline]="true"
                          [dataLabelField]="'name'"
                          [dataValueField]="'name'">
                        </app-radio-button-list>
                    </div>
                    <div class="col-xs-12">
                        <label for="modelName" class="col-form-label col-form-label-sm">
                          Model Name
                        </label>
                        <input kendoTextBox
                          formControlName="modelName"
                          id="modelName"
                          class="form-control"
                          placeholder="Model Name"/>
                        <validation-error-message [control]="modelName"></validation-error-message>
                    </div>
                    <div class="col-xs-12">
                        <label for="modelNumber" class="col-form-label col-form-label-sm">
                          Model Number
                        </label>
                        <input kendoTextBox
                          formControlName="modelNumber"
                          id="modelNumber"
                          class="form-control"
                          placeholder="Model Number"/>
                        <validation-error-message [control]="modelNumber"></validation-error-message>
                    </div>
                    <div class="col-xs-12">
                        <label for="manualLink" class="col-form-label col-form-label-sm">
                          Manual Link
                        </label>
                        <input kendoTextBox
                          formControlName="manualLink"
                          id="manualLink"
                          class="form-control"
                          placeholder="Manual Link"/>
                        <validation-error-message [control]="manualLink"></validation-error-message>
                    </div>
                    <div class="col-xs-12">
                        <label for="trainingLink" class="col-form-label col-form-label-sm">
                          Training Link
                        </label>
                        <input kendoTextBox
                          formControlName="trainingLink"
                          id="trainingLink"
                          class="form-control"
                          placeholder="Training Link"/>
                        <validation-error-message [control]="trainingLink"></validation-error-message>
                    </div>
                    <div class="col-xs-12">
                        <label for="notes" class="col-form-label col-form-label-sm">
                          Notes
                        </label>
                        <textarea kendoTextBox
                          formControlName="notes"
                          id="notes"
                          class="form-control"
                          placeholder="Notes">
                        </textarea>
                        <validation-error-message [control]="notes"></validation-error-message>
                    </div>
                    <div class="col-xs-12">
                        <label for="description" class="col-form-label col-form-label-sm">
                          Description
                        </label>
                        <textarea kendoTextBox
                          formControlName="description"
                          id="description"
                          class="form-control"
                          placeholder="Description">
                        </textarea>
                        <validation-error-message [control]="description"></validation-error-message>
                    </div>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Metadata'" [disabled]="!editMode">
            <ng-template kendoTabContent>
                <app-metadata [metadata]="dataModel"></app-metadata>
            </ng-template>
        </kendo-tabstrip-tab>
    </kendo-tabstrip>
</form>
